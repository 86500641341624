import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "krapukhin_four_goal_contributions_in_debut" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Российский нападающий рижского футбольного клуба Станислав Крапухин вчера в своем первом официальном матче в Вирслиге забил два гола и отдал две результативные передачи.  Спортивное агентство Catenaccio поздравляет Станислава и желает ему всего наилучшего в следующих играх.</p>
        </div>
      </div>
    )
  }
}